<template>
  <div id="app">
    <router-view />
    <van-overlay :show="$store.state.reqLoading">
      <div class="reporting-wrapper">
        <van-loading type="spinner" color="#1989fa" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { isMobile, getUrlParams } from '@/utils'

export default {
  name: 'App',
  beforeDestroy() {
    sessionStorage.clear()
  },
  created() {
    this.checkDevice()
    this.checkAuthCode()
  },
  mounted() {
    // 防止amfe-flexible插件加载延迟,根元素计算错误
    (function flexible(window, document) {
      var docEl = document.documentElement
      var dpr = window.devicePixelRatio || 1

      // adjust body font size
      function setBodyFontSize() {
        if (document.body) {
          document.body.style.fontSize = (12 * dpr) + 'px'
        } else {
          document.addEventListener('DOMContentLoaded', setBodyFontSize)
        }
      }
      setBodyFontSize()

      // set 1rem = viewWidth / 10
      function setRemUnit() {
        var rem = docEl.clientWidth / 10
        docEl.style.fontSize = rem + 'px'
      }
      setRemUnit()

      // reset rem unit on page resize
      window.addEventListener('resize', setRemUnit)
      window.addEventListener('pageshow', function(e) {
        if (e.persisted) {
          setRemUnit()
        }
      })

      // detect 0.5px supports
      if (dpr >= 2) {
        var fakeBody = document.createElement('body')
        var testElement = document.createElement('div')
        testElement.style.border = '.5px solid transparent'
        fakeBody.appendChild(testElement)
        docEl.appendChild(fakeBody)
        if (testElement.offsetHeight === 1) {
          docEl.classList.add('hairlines')
        }
        docEl.removeChild(fakeBody)
      }
    }(window, document))
  },
  methods: {
    // 校验权限,无权限跳转到无权限页面
    checkAuthCode() {
      const params = getUrlParams(window.location.href)
      console.log(params, 'params')
      const authCode = params.auth_code || '0'

      sessionStorage.setItem('auth_code', authCode)
      if (authCode === '0') {
        if (process.env.NODE_ENV === 'production') {
          // 无权限
          this.$router.push('/unauthorize')
        }
      } else {
        // 有权限
        sessionStorage.setItem('access_token', params.access_token || '')
        if (params.jump) {
          console.log(params, 'params222', params.params)
          this.$router.push({
            name: params.jump,
            query: params.params ? JSON.parse(decodeURIComponent(params.params)) : {}
          })
        }
      }
    },
    // 设备校验
    checkDevice() {
      if (process.env.NODE_ENV === 'production') {
        if (!isMobile() || !navigator.userAgent.indexOf('wxwork')) {
          this.$dialog.alert({
            message: '请在移动端企业微信中打开'
          }).then(() => {
            this.$router.replace('/checkdevice')
          })
          return false
        }
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  width: 100vw;
  height: 100vh;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS<11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容iOS>= 11.2 */
  .reporting-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
</style>
