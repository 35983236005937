import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible'
import {
  Col, Row, Button, Image as VanImage,
  Divider, Field, Popup, Picker, Toast,
  Grid, GridItem, DatetimePicker, Loading,
  Overlay, List, Cell, CellGroup, Dialog, Search, Popover,
  Sticky, Icon, CountDown, PullRefresh, Tab, Tabs
} from 'vant'
import './assets/styles/reset.css'
// import './utils/rem.js'

import VConsole from 'vconsole'
Vue.use(Icon)

Vue.use(Button).use(Col).use(Row).use(VanImage)
  .use(Divider).use(Field).use(Popup).use(Picker)
  .use(Toast).use(Grid).use(GridItem).use(DatetimePicker)
  .use(Loading).use(Overlay).use(List).use(Cell).use(CellGroup)
  .use(Dialog).use(Search).use(Popover).use(Sticky)
  .use(Icon).use(CountDown).use(PullRefresh).use(Tab).use(Tabs)
// 如果是生产环境,则不显示vconsole
const isProd = process.env.NODE_ENV === 'production'
if (!isProd) {
  const vConsole = new VConsole()
  Vue.use(vConsole)
}

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
